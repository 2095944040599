<template>
  <div>
    <CTabs variant="pills" :active-tab="0">
      <CTab title="URL">
        <CTextarea
          id="campurlforselect"
          rows="3"
          v-model="url_data.url"
          type="text"
          class="mt-3 url_text"
        />
        <CRow class="ml-1">
          <span style="color: red">*</span>
          <span
            >You can Change macros subXX to any macros. For example ?{sub1} =
            {clicktid}</span
          >
        </CRow>
        <CRow class="ml-1">
          <span style="color: red">*</span>
          <span
            >{rev}
            Revenue on Conversion
          </span>
        </CRow>
        <CButton color="dark" @click="collapseurl = !collapseurl" class="m-1"
          >add URL Param
        </CButton>
        <CButton color="dark" class="m-1" @click="copyToClipboard('URL')">
          Copy URL
        </CButton>
        <CCollapse id="collapse-2" :show="collapseurl">
          <CRow style="margin-left: 1px">
            <div
              v-for="(value, index) in url_param"
              @click="addUrl_Param('URL', index)"
              :key="index"
            >
              <CButton
                style="margin: 2px 2px 5px 2px; width: 70px"
                color="primary"
                variant="outline"
                >{{ value }}</CButton
              >
            </div>
          </CRow>
        </CCollapse> </CTab
      >
      <!-- <CTab title="SDK">
        <label>SDK Code</label>
        <CRow>
          <CTextarea
            id="sdktext"
            v-model="url_data.sdk_data"
            rows="8"
            cols="150"
            type="text"
            class="url_text col-md-12"
          />
        </CRow>
        <label>SDK Url</label>
        <CRow>
          <CTextarea rows="2" cols="150" v-model="url_data.sdk_url" class="col-md-12 url_text"/>
        </CRow>
        <CRow class="ml-1">
          <span style="color: red">*</span>
          <span
            >You can Change macros subXX to any macros. For example ?{sub1} =
            {clicktid}</span
          >
        </CRow>
        <CRow class="ml-1">
          <span style="color: red">*</span>
          <span
            >{rev}
            Revenue on Conversion
          </span>
        </CRow>
        <CRow class="ml-1">
          <span style="color: red">*</span>
          <CHeaderNavLink to="/document/sdkdocument">
            SDK Documentation
          </CHeaderNavLink>
        </CRow>
        <CButton color="dark" @click="collapsesdk = !collapsesdk" class="m-1"
          >add SDK Param
        </CButton>
        <CButton color="dark" class="m-1" @click="copyToClipboard('SDK')">
          Copy SDK
        </CButton>
        <CCollapse id="collapse-2" :show="collapsesdk">
          <CRow style="margin-left: 1px">
            <div
              v-for="(value, index) in sdk_url_param"
              @click="addUrl_Param('SDK', index)"
            >
              <CButton
                style="margin: 2px 2px 5px 2px; width: 70px"
                color="primary"
                variant="outline"
                >{{ value }}</CButton
              >
            </div>
          </CRow>
        </CCollapse>
      </CTab> -->
      <!-- <CTab title="0ms URL">
        <CRow class="p-3">
          <CTextarea
            id="zeromsurl"
            rows="4"
            cols="150"
            v-model="url_data.zeroms_url"
            type="text"
            class="url_text"
          />
        </CRow>
        <CRow class="ml-1">
          <span style="color: red">*</span>
          <span
            >You can Change macros subXX to any macros. For example ?{sub1} =
            {clicktid}</span
          >
        </CRow>
        <CRow class="ml-1">
          <span style="color: red">*</span>
          <span
            >{rev}
            Revenue on Conversion
          </span>
        </CRow>
        <CButton
          color="dark"
          @click="collapsezeroms = !collapsezeroms"
          class="m-1"
          >add URL Param
        </CButton>
        <CButton color="dark" class="m-1" @click="copyToClipboard('msurl')">
          Copy URL
        </CButton>
        <CCollapse id="collapse-2" :show="collapsezeroms">
          <CRow style="margin-left: 1px">
            <div
              v-for="(value, index) in zeroms_url_param"
              @click="addUrl_Param('zeroMs', index)"
            >
              <CButton
                style="margin: 2px 2px 5px 2px; width: 70px"
                color="primary"
                variant="outline"
                >{{ value }}</CButton
              >
            </div>
          </CRow>
        </CCollapse>
      </CTab> -->
    </CTabs>
  </div>
</template>


<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
export default {
  name: "UrlParams",
  props: { url_data:{}},
  data() {
    return {
      collapseurl: false,
      collapsesdk: false,
      collapsezeroms: false,
      url_param: {sub1: "sub1",sub2: "sub2",sub3: "sub3",sub4: "sub4",sub5: "sub5",sub6: "sub6",sub7: "sub7",sub8: "sub8",sub9: "sub9",sub10: "sub10",sub11: "sub11",sub12: "sub12"},
      zeroms_url_param: {sub1: "sub1",sub2: "sub2",sub3: "sub3",sub4: "sub4",sub5: "sub5",sub6: "sub6",sub7: "sub7",sub8: "sub8",sub9: "sub9",sub10: "sub10",sub11: "sub11",sub12: "sub12"},
      sdk_url_param: {sub1: "sub1",sub2: "sub2",sub3: "sub3",sub4: "sub4",sub5: "sub5",sub6: "sub6",sub7: "sub7",sub8: "sub8",sub9: "sub9",sub10: "sub10",sub11: "sub11",sub12: "sub12"},
    };
  },
  computed: {
    url() {
      return this.url_data.url;
    },
    sdk_url(){
      return this.url_data.sdk_url;
    },
    zeroms_url(){
      return this.url_data.zeroms_url;
    }
  },
  watch: {
    url() {
      this.checkButton(this.url_data.url, "url");
    },
    zeroms_url(){
      this.checkButton(this.url_data.zeroms_url,'zeroms')
    },
    sdk_url(){
      this.checkButton(this.url_data.sdk_url, "sdk_data");
    }
  },
  methods: {
    decodeHTMLEntities(text) {
      var entities = [
        ["amp", "&"],
        ["apos", "'"],
        ["#x27", "'"],
        ["#x2F", "/"],
        ["#39", "'"],
        ["#47", "/"],
        ["lt", "<"],
        ["gt", ">"],
        ["nbsp", " "],
        ["quot", '"'],
      ];

      for (var i = 0, max = entities.length; i < max; ++i)
        text = text.replace(
          new RegExp("&" + entities[i][0] + ";", "g"),
          entities[i][1]
        );

      return text;
    },
    checkButton(url, d) {
      var params = {"sub1": "sub1","sub2": "sub2","sub3": "sub3","sub4": "sub4","sub5": "sub5",
          "sub6": "sub6","sub7": "sub7","sub8": "sub8","sub9": "sub9","sub10": "sub10",
          "sub11": "sub11","sub12": "sub12"}
      for (let e in params) {
        if (url.match(new RegExp(e + "="))) delete params[e];
        switch (d) {
          case "url":
            this.url_param = params;
            break;
          case "zeroms":
            this.zeroms_url_param = params;
            break;
          case "sdk_data":
            this.sdk_url_param = params;
            var sdk_data =
            "&lt;script src=&quot;" +
            this.url_data.sdk_url +
            "&quot; defer&gt;&lt;/script&gt;\n" +
            "&lt;script&gt;\n" +
            "document.addEventListener(&quot;rendered&quot;, function (e) {\n" +
            "var event_id=e.detail;\n" +
            "/* your code to do xhr request to your own api, your own api will call fraudAvoid api to validate the user. */\n" +
            "})&lt;/script&gt;";
            this.url_data.sdk_data = this.decodeHTMLEntities(sdk_data);
            break;
        }
      }
    },
    copyToClipboard(txt) {
      // console.log("COPY");
      var textField;
      switch (txt) {
        case "URL":
          textField = document.getElementById("campurlforselect");
          break;
        case "SDK":
          textField = document.getElementById("sdktext");
          break;
        case "msurl":
          textField = document.getElementById("zeromsurl");
          break;
      }
      textField.select();
      textField.focus(); //SET FOCUS on the TEXTFIELD
      document.execCommand("copy");
      if(textField.value != ''){
      Swal.fire({
        title: "Success!",
        text: "URL Copied!!",
        icon: "success",
        confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
      });
      }
      else{
        Swal.fire({
          title: "Error!",
          text: "URL is empty!!",
          icon: "error",
          confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
        });
      }
      // this.errorNotify("Success", "URL Copied!!", "success");
    },
    addUrl_Param(txt, data) {
      var str1 = "?";
      var str3 = data.toLowerCase();
      var str4 = "={" + data + "}";

      switch (txt) {
        case "URL":
          for (var i = 0; i < this.url_data.url.length; i++) {
            if (this.url_data.url.charAt(i) == "?") {
              var str1 = "&";
            }
          }
          let url_text = str1.concat(str3.concat(str4));
          this.url_data.url = this.url_data.url.concat(url_text);
          break;
        case "SDK":
          for (var i = 0; i < this.url_data.sdk_url.length; i++) {
            if (this.url_data.sdk_url.charAt(i) == "?") {
              var str1 = "&";
            }
          }
          let sdk_url_text = str1.concat(str3.concat(str4));
          this.url_data.sdk_url = this.url_data.sdk_url.concat(sdk_url_text);
          var sdk_data =
            "&lt;script src=&quot;" +
            this.url_data.sdk_url +
            "&quot; defer&gt;&lt;/script&gt;\n" +
            "&lt;script&gt;\n" +
            "document.addEventListener(&quot;rendered&quot;, function (e) {\n" +
            "var event_id=e.detail;\n" +
            "/* your code to do xhr request to your own api, your own api will call fraudAvoid api to validate the user. */\n" +
            "})&lt;/script&gt;";
          this.url_data.sdk_data = this.decodeHTMLEntities(sdk_data);
          break;
        case "zeroMs":
          for (var i = 0; i < this.url_data.zeroms_url.length; i++) {
            if (this.url_data.zeroms_url.charAt(i) == "?") {
              var str1 = "&";
            }
          }
          let zeroms_url_text = str1.concat(str3.concat(str4));
          this.url_data.zeroms_url = this.url_data.zeroms_url.concat(zeroms_url_text);
          break;
      }
      data.toLowerCase();
    },
  },
};
</script>