<template>
  <div class="tooltip-box">
    <slot />
    <div class="tooltip">
      <span class="text">
          <CRow>
              <CCol>
                  <CChartBar
                    :datasets="text2"
                    :labels="pielabel2"
                     v-if="showPie"
                    />
                  <!-- <CChartPie :datasets="text2" :labels="pielabel2" v-if="showPie" /> -->
                  
              </CCol>
              <CCol>
                  <CChartPie :datasets="text" :labels="pielabel1" v-if="showPie" />
              </CCol>
              <CCol>
                <CButton class="mt-5" color="info" @click="ShowHeatMap()">Show Heat Map</CButton>
              </CCol>
          </CRow>
        
        <span class="text" v-if="showPie == false">
          <h3 style="color: black; font-weight: 800">loading...</h3>
        </span>
      </span>
    </div>
    <!-- Show Model -->
    <CModal
      title="Heat Map"
      :show.sync="show_heatmap"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <CCard class="col-md-12">
      <CRow class="col-md-12 mt-3">
        <CCol class="text-left">
          <span v-if="devicetype == 'SmartPhone'">
            <CIcon class="icon_color" :height="42" name="cil-mobile" ></CIcon>
          </span>
          <span v-else @click="UpdateDevice('SmartPhone')">
            <CIcon :height="35" name="cil-mobile"></CIcon>
          </span>
          <span v-if="devicetype == 'Desktop'">
            <CIcon  class="icon_color" :height="42" name="cil-laptop" ></CIcon>
          </span>
          <span v-else @click="UpdateDevice('Desktop')">
            <CIcon :height="35" name="cil-laptop" ></CIcon>
          </span>
        </CCol>
        <CCol class="text-right">
          <span v-if="dataType == 'Image'">
            <CIcon class="icon_color" :height="42" name="cil-image" ></CIcon>
          </span>
          <span v-else @click="FetchHeatmapData('Image')">
            <CIcon :height="35" name="cil-image"></CIcon>
          </span>
          <span v-if="dataType == 'Video'">
            <CIcon  class="icon_color" :height="42" name="cil-video" ></CIcon>
          </span>
          <span v-else @click="FetchHeatmapData('Video')">
            <CIcon :height="35" name="cil-video" ></CIcon>
          </span>
        </CCol>
      </CRow> 
      <CRow class="col-md-12 pt-5">
          <h3 v-if="show_no_data_message">{{ no_data_message }}</h3>
      </CRow>
      <CRow class="col-md-12 pt-1">
          <CCol>
            <center>
              <video
              v-show="show_video"
              style="border: 2px solid black;"
              class="video_tag"
              ref="hmVideoRef"
              src=""
              id="video-container"
              width="50%"
              height="100%"
              controls
            ></video>
            </center>
          </CCol>
        </CRow>
      <div v-if="loading_data==true" class="col-md-12 pt-1">
          <center>
              <CElementCover
                :boundaries="[{ sides: ['top', 'left'], query: '.media-body' }]"
                :opacity="0.8"
              >
            <h1 class="d-inline">Loading...</h1>
              <CProgress class="mb-5">
              <CProgressBar
                color="success"
                variant="striped"
                animated
                :value="parseInt(uploadVStatus)"
              />
            </CProgress>
              </CElementCover>
            </center>
        </div>
      <CRow class="col-md-12" v-show="show_image">
          <CCol>
            <center>
              <img
                :src="image_url"
                class="img-fluid"
                style="border: 5px solid black;"
              />
            </center>
          </CCol>
        </CRow>
      
      
    </CCard>
      <template #footer>
        <CButton @click="show_heatmap = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>
  </div>
</template>

<script>
import { CChartPie } from "@coreui/vue-chartjs";
import { CChartBar } from "@coreui/vue-chartjs";
import ReportsController from "../services/reportsController";
let socket;
export default {
  components: {
    CChartPie,
    CChartBar
  },
  props: {
    text: {
      type: Array,
      required: true,
    },
    text2: {
      type: Array,
      required: true,
    },
    pielabel1: {
      type: Array,
      required: true,
    },
    pielabel2: {
      type: Array,
      required: true,
    },
    showPie: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowData:{},
    camp_id:{},
    heatMap_payload:{}
  },
  watch: {
    showPie: function () {
      console.log("show Chart");
    },
  },
  data(){
    return{
      show_heatmap : false,
      loading_data: false,
      show_image : false,
      show_video: false,
      uploadVStatus: 0,
      show_no_data_message: false,
      no_data_message: "No data available !!!",
      image_url:'',
      show_video_button: false,
      show_no_video_message: false,
      devicetype:'SmartPhone',
      dataType:'Image'
    }
  },
  methods:{
   async ShowHeatMap(){
      this.show_heatmap = true
      this.FetchHeatmapData('Image')
    },
   UpdateDevice(txt){
      this.devicetype = txt
      this.FetchHeatmapData('Image')
    },
  async FetchHeatmapData(txt){
    this.dataType = txt
    this.loading_data=true;
    this.show_image = false;
    this.show_video = false;
    this.uploadVStatus = 0
      var payload = {
        "campid": this.camp_id,
        "devicetype": this.devicetype,
        "date": this.rowData.date,
        "viewby": this.heatMap_payload.viewby,
        "groupby": this.heatMap_payload.groupby,
        "timezone": this.heatMap_payload.timezone
      }
      if (this.validate()) {
      try {
        this.show_no_data_message = false
        var response
      // Image API call
      if(txt == 'Image'){
       response = await ReportsController.getheatMapdataImage(payload);
       this.loading_data = false
       if (response.result) {
            this.image_url = response.imageUrl;
            this.show_image = true;
            this.show_video = false;
          } else {
            this.show_no_data_message = true
          }
      }
      //  Video API Call
      if(txt == 'Video'){
         response = await ReportsController.getheatMapdataVideo(payload);
        if (response.result) { 
           
            try {
              if (socket.readyState == WebSocket.OPEN) {
                socket.close();
              }
            } catch (e) {
              console.log("ERROR: error in check status" + e);
            }

            socket = new WebSocket(
              process.env.VUE_APP_SOCKETURL + "/" + response.videoUrl
            );
            let p;
            let videoId = response.videoUrl;

            socket.onopen = function (e) {
              console.log("[open] Connection established");
              console.info("Sending to server");
              let data = {
                event: "hmstatus",
                data: videoId,
              };
              socket.send(JSON.stringify(data));
            };
            socket.onerror = function (error) {
              console.error("error:", error);
              throw new Error("Unable to Open Socket");
            };
            socket.onclose = function (e) {
              console.log("[close] connection closing");
            };
            socket.onmessage = function (event) {
              //  console.log(event)
              //  console.log("received data"+event.data)
              try {
                let socketResponse = JSON.parse(event.data);
                this.uploadVStatus = socketResponse.status;
                if (this.uploadVStatus == "100") {
                  this.show_video_button = false;
                  this.loading_data = false;
                  if (socketResponse.videoUrl) {
                    socket.close(1000, "Work complete");
                    this.loading_data = false
                    this.show_video = true;
                    // this.$refs.hmVideoRef.src = ""
                    this.$refs.hmVideoRef.src = socketResponse.videoUrl;
                    this.$refs.hmVideoRef.play();
                   
                  } else {
                    socket.close(1000, "Work complete");
                    Swal.fire({
                      title: "Error!",
                      text: "Video Not Available !!!",
                      icon: "error",
                      confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
                    });
                    this.show_no_video_message = true;
                    this.show_video_button = false;
                    this.show_video = false;
                  
                  }
                  
                }
              } catch (e) {
                console.log(e);
              }
            }.bind(this);
          } else {
                this.loading_data = false
                this.show_no_data_message = true
            }
      }
      } catch (e) {
          // alert("connection Error!");
        }
      } else {
        alert("Please Select Campaign");
      }
  },
  validate() {
      if (this.camp_id == undefined || this.camp_id == "") {
        return false;
      }
      return true;
    },
  }
};
</script>

<style scoped>
.tooltip-box {
  position: relative;
  display: inline-block;
}

.tooltip-box:hover .tooltip {
  opacity: 1;
  width: 100vh;
  display: block;
}

.tooltip {
  color: #ffffff;
  text-align: center;
  padding: 5px 0;
  border-radius: 2px;
  border: 2px solid black;
  width: 0px;
  left: 80%;
  top: 50%;
  margin-left: -60px;
  display: none;
  opacity: 0;
  transition: opacity 1s;

  position: absolute;
  z-index: 1;

  background: #e3e3e4;
}
.icon_color{
  color: #0041f4;
}
/* .text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
} */
</style>
